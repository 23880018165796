/*
  ContactPage.js
  section 1: 
  section 2: 
*/
import React, { useEffect, useRef } from 'react';
// Style:
import '../../styles/about.scss';
// components
// import { Flex, Timeline } from 'antd';
import { RightOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { ButtonMain } from '../../components/buttons/Button_Common';
import gsap from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// Assets:
// const applyai = '/assets/portfolio/about/logo_name.png';
const me_img = "/assets/portfolio/me.png";
// const me_img = "https://i.ibb.co/nb6j4wB/me.png";
// const resume_link = "https://pdfjm.com/api/pdf/pdf?id=NDU2Mjg=";
// const resume_link = "https://drive.google.com/file/d/10oxxNJqBz6b2MarF7uejr7INLwM02zC4/view?usp=sharing"


const AboutPage = () => {
    const containersRef = useRef([]);

    useEffect(() => {
        const animations = containersRef.current.map((container) => {
            return gsap.fromTo(
                container,
                { autoAlpha: 0, y: 0 }, // Start with opacity 0 and translateY 50px
                {
                    autoAlpha: 1, // Animate to opacity 1
                    y: 0, // Animate to translateY 0
                    duration: 0.8,
                    scrollTrigger: {
                        trigger: container,
                        start: "top 70%", // Start the animation when the container is 80% from the top of the viewport
                        toggleActions: "play none none none", // Animation will reverse when scrolling back up
                    },
                }
            );
        });

        // Cleanup function to kill ScrollTriggers on unmount
        return () => {
            animations.forEach(animation => animation.scrollTrigger && animation.scrollTrigger.kill());
        };
    }, []);

    return (
        <>
            <div className="about-section">
                <section className='top-section'>
                    <h1>A little bit about myself.</h1>
                    <div className='descrip-section'>
                        <p>As a User Experience Designer, UI Designer, and Front-End Engineer, I am passionate about the intersection of design, technology, and human behavior. My professional journey has equipped me with a diverse skill set, spanning 2D and 3D interface design, video prototyping, and graphic design, as well as front-end development for web, mobile, and mixed reality applications.
                            <br /><br />Professionally, I have worked with companies such as Xreal, Baidu, Changhong Jiahua, and BMW, where I contributed to projects ranging from mobile app interfaces and 3D spatial user experiences to mixed reality development and installation art. My experience includes usability testing, user research, product design, data analysis, mixed-reality development and generative art, leveraging tools like Python, Java, C#, Swift, and various front-end frameworks.
                            <br /><br />I'm passionate about using emerging technologies to improve designs and solve complex challenges, always with a focus on learning and innovation.
                        </p>
                        <div className="image-container">
                            <div className='img-bg'></div>
                            <img ref={(el) => (containersRef.current[0] = el)} src={me_img} alt="me" border="0"></img>
                        </div>
                    </div>
                    <ButtonMain
                        onClick={() => {
                            window.open("https://pdfjm.com/api/pdf/pdf?id=NDU2Mjg=", '_blank');
                        }}
                    >
                        <span style={{ marginRight: '5px', marginLeft: '8px' }}>View my CV</span><RightOutlined />
                    </ButtonMain>
                    <ButtonMain
                        style={{ marginTop: '14px' }}
                        onClick={() => {
                            window.open("https://pdfjm.cn/api/pdf/pdf?id=NDU2Mjk=", '_blank');
                        }}
                    >
                        <span style={{ marginRight: '5px', marginLeft: '8px' }}>我的简历</span><RightOutlined />
                    </ButtonMain>
                </section>
                <section ref={(el) => (containersRef.current[1] = el)} className='addition-section'>
                    <h2>Skills</h2>
                    <div className='content'>
                        <div className='lists'>
                            <div className='list'>
                                <h4>Interactive Design</h4>
                                <ul>
                                    <li>UI design</li>
                                    <li>UX research</li>
                                    <li>Wireframing</li>
                                    <li>Prototyping</li>
                                    <li>Usability testing</li>
                                    <li>Information architecture</li>
                                    <li>Data analysis</li>
                                </ul>
                            </div>
                            <div className='list'>
                                <h4>Visual Design</h4>
                                <ul>
                                    <li>Data visualization</li>
                                    <li>Graphic design</li>
                                    <li>Branding design</li>
                                    <li>Motion design</li>
                                    <li>Animation</li>
                                </ul>
                            </div>
                            <div className='list'>
                                <h4>Frontend Development</h4>
                                <ul>
                                    <li>React.js / Next.js / React Native</li>
                                    <li>Unity (C#)</li>
                                    <li>Unreal (Blueprint)</li>
                                    <li>XCode (Swift/SwiftUI/Storyboard)</li>
                                    <li>Python Tkinter</li>
                                </ul>
                            </div>
                            <div className='list'>
                                <h4>Mixed Reality Development</h4>
                                <ul>
                                    <li>Unity (AR Foundation / XR Hands / PolySpatial / XR Interaction...)</li>
                                    <li>VisionOS (ARKit / RealityKit)</li>
                                    <li>Maya (Modeling / Scripting)</li>
                                    <li>Reality Composer</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section ref={(el) => (containersRef.current[2] = el)} className='learnmore'>
                    <div className='slogan'>
                        <h2>Get in touch!</h2>
                        <p>Feel free to reach out for collaborations or just a friendly hello 😀</p>
                    </div>
                    <div className='contact'>
                        <h4>Email</h4>
                        <a href="mailto:haominglang0325@gmail.com" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ display: 'flex', gap: '4px', alignItems: 'center', justifyContent: 'center' }}>
                                <p>Get in touch</p>
                                <ArrowRightOutlined />
                            </div>
                        </a>
                    </div>
                </section>
            </div>
            {/* <Contact/> */}
        </>
    );
}

export default AboutPage;